export const apiHostName="https://n44bjo1126.execute-api.us-east-1.amazonaws.com/dev"

export const getPartnerURL= process.env.REACT_APP_API_HOSTNAME+"/Prod/partners"
export const getStatesURL= process.env.REACT_APP_API_HOSTNAME+"/Prod/states"
export const companyDetailsURL= process.env.REACT_APP_API_COMPANYHOSTNAME+'/Prod/companydetail'
export const getCompanyListURL = process.env.REACT_APP_API_COMPANYHOSTNAME+'/Prod/companydetail/list'
export const getEmployeeListURL = process.env.REACT_APP_API_EMPLOYEEHOSTNAME+'/Prod/employee/list'
export const employeeDetailsURL = process.env.REACT_APP_API_EMPLOYEEHOSTNAME+'/Prod/employee'
export const validateEmailURL = process.env.REACT_APP_API_COMPANYHOSTNAME+'/Prod/companyDetail/sendEmail'
export const getuserDetailsURL = process.env.REACT_APP_API_COMPANYHOSTNAME+'/Prod/companyDetail/verifyLogintoken'
export const saveEmployerResourceURL = process.env.REACT_APP_API_COMPANYHOSTNAME+'/Prod/companyDetail/saveEmployerResource'
export const getEmployerResourceURL = process.env.REACT_APP_API_COMPANYHOSTNAME+'/Prod/companyDetail/getEmployerResource'
export const getEmployeeListFilterURL = process.env.REACT_APP_API_EMPLOYEEHOSTNAME+'/Prod/employee/search/byFilter'